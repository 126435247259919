.body {
	margin: 0;
	padding: 0;
}

.Home {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0.5)), url(./boucher.png);
	background-size: cover;
	background-position: top;
	position: absolute;
	top: 0;
	filter: grayscale(100%) saturate(20%);
	height: 100vh;
	width: 100vw;
	transition: 2s ease;
	transition-delay: 500ms;
	box-sizing: border-box;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	padding: 5vh 0;
	z-index: -1;
}

@media only screen and (max-width: 600px) {
	.Home {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0.2)), url(./BoucherSmall.png);
	}
}

.colorize {
	filter: grayscale(0%) saturate(120%);
	/* box-shadow: inset 0 0 100px black; */
	/* clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%); */
}

.text-box {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	filter: blur(0);
	width: 95vw;
}

.email-form {
	margin: 5px;
}

.f-icon {
	position: absolute;
	bottom: 0;
}

.heading-primary {
	color: white;
	font-size: 4em;
	letter-spacing: 10px;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, .8);
	margin: 0;
	font-weight: 100;
	animation: slideLeft 1s ease-out;
	backface-visibility: hidden;
}

.heading-secondary {
	color: white;
	font-size: 1.5em;
	letter-spacing: 5px;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, .8);
	margin: 0;
	font-weight: 400;
	animation: slideRight 1s ease-out;
	backface-visibility: hidden;
	margin-bottom: 5vh;
}

@keyframes slideLeft {
	0% {
		transform: translateX(-10%);
		opacity: .01;
	}
	,
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideRight {
	0% {
		transform: translateX(10%);
		opacity: .01;
	}
	,
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
