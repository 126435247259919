.Loading-Box {
	width: 100vw;
	height: 100vh;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0.5)), url(./boucher.png);
	filter: grayscale(100%);
	background-size: cover;
}

@media only screen and (max-width: 600px) {
	.Loading-Box {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0.2)), url(./BoucherSmall.png);
		background-size: cover;
	}
}
