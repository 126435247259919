.admin-table {
	width: 95vw;
	text-align: left;
	margin: 0 auto;
}

tr:nth-child(even) {
	background-color: #f2f2f2;
}

.table-row:hover {
	background-color: #c7c7c7;
}

.table-row {
	height: 5vh;
	transition: all .5s ease;
}
